<template>

  <b-card-code>
    
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
  
        <add-new
          :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"    
          :dataSlider="sidebarData"  
          :adminType="adminType"
          :regions="regions"
          @refreshData="refreshData"
        />

        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
        <b-button
          style="margin-left: 10px ;"
            @click="addNewData"
            variant="outline-primary"
            class="mb-2"
          >
            Add Admin
          </b-button>
          

          <b-table 
            responsive
            :items="items" 
            :fields="tableColumns" 
            striped 
            class="position-relative"
          >

          <template #cell(id)="data">
              {{data.index + 1}}
          </template>


          <template #cell(created_at)="data" >
              {{data.value | moment('YY-MM-DD HH:mm:ss')}}
          </template>

          <template #cell(type)="data">
            {{data.value.name}} 
          </template>


          <template #cell(status)="data">
            <b-badge @click="changeStatus(data.item.id, data.item.status)" :variant="status[1][data.value]" style="cursor: pointer;">
              {{ status[0][data.value] }}
            </b-badge>
          </template>


          <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item> -->

                <b-dropdown-item @click="editData(data.item)" >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="changePassword(data.item.id)" > 
                  <feather-icon icon="KeyIcon" />
                  <span  class="align-middle ml-50">Change Password</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item @click="deleteData(data.item.id)" > 
                  <feather-icon icon="TrashIcon" />
                  <span  class="align-middle ml-50">Delete</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>

          </b-table>
        

        </b-overlay>
      <!-- </div> -->
  </b-card-code>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton,BCardBody } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'


export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    BCardBody,
    ToastificationContent,
    BCardCode
  },

  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      adminType: [],
      tableColumns: [
        { key: 'id', sortable: true },
        { key: 'name', label: ' Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created_by', label: 'Created by', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        { key: 'actions' },
      ],
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
      
    }
  },

  methods: {
    getData(){
      this.isLoading = true;
      DataService.getAll()
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },

    getType(){
      this.isLoading = true;
      DataService.getAdminType()
      .then(response => {
        this.isLoading = false
        this.adminType = response.data.data
      })
    },
    deleteData(id){
      this.isLoading = true;
      DataService.delete(id)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    changePassword(id){
      this.isLoading = true;
      DataService.changePassword(id)
      .then(response => {
        this.isLoading = false
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    changeStatus(id, status){
      
      this.isLoading = true;
      DataService.update(id, {status: !status})
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    }
  },
  computed: {
    regions (){
      return this.$store.getters['admin/GetRegions'] 
    }
  },
  mounted() {
    this.getData()
    this.getType()
  },
  
}
</script>